<template>
  <van-form @submit="onSubmit">
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            v-model="form.g_name"
            label="公司名称"
        />
        <van-field
            readonly
            label-width="100"
            v-model="form.g_company_code"
            label="社会统一代码"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            v-model="form.g_hang_ye"
            label="行业"
        />
        <van-field
            readonly
            v-model="form.php_address"
            label="地址"
        />
        <van-field
            readonly
            v-model="form.g_address"
            label="详细地址"
        />
      </van-cell-group>
    </div>

    <div class="form-btn">
      <van-button round block :to="{name:'UserCompanyInfoEdit'}" native-type="submit">
        编辑
      </van-button>
    </div>
  </van-form>
  <gong-si-cao-zuo v-if="isFzr" />
</template>

<script>
import AddImg from "../../../components/file/AddImg";
import http from "../../../api/http";
import XEUtils from "xe-utils";
import GongSiCaoZuo from "./gongSiCaoZuo";
//预览服务商信息
export default {
  name: 'CompanyInfo',
  components: {GongSiCaoZuo, AddImg},
  mixins: [],
  data() {
    return {
      form: {
      }
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      http.get('/gzhphp/user/getGongSiInfo').then(da => {
        if (da.code === 1) {
          if (XEUtils.isObject(da.data)) {
            da.data.php_address = da.data?.php_address?.name?.join('/')
            this.form = da.data
          }
        }
      })
    },
    onSubmit() {

    }
  },
  watch: {},
  computed: {}
}
</script>
<script setup>
import {userBasis} from "@/views/user/public/Basis";

let {isFzr} = userBasis()
</script>

<style scoped>

</style>
