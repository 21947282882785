<template>
  <van-form @submit="onSubmit">
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            v-model="form.nickname"
            label="姓名"
        />
        <van-field
            v-model="form.email"
            label="邮箱"
        />
        <van-field
            v-model="form.wx_number"
            label="微信号"
        />
      </van-cell-group>

    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            label="擅长领域"
        >
          <template #input>
            <div>
              <checkbox_btn
                  v-model:id="form.shan_chang"
                  v-model:name="form.shan_chang_name"
                  :list="ji_neng_list"
              />
            </div>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            v-model="form.name"
        >
          <template #input>
            <div style="width: 100%">
              <div style="margin-bottom: 10px">
                <fen-ge-fu>曾就职公司/职位</fen-ge-fu>
              </div>
              <div>
                <ceng-jiu-zhi-gong-si v-model:list="form.json_gcs_gzjl" ref="jiuZHi"/>
              </div>
            </div>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <div class="form-btn">
      <van-button round block type="primary" native-type="submit">
        编辑
      </van-button>
    </div>
  </van-form>
</template>

<script>
import Checkbox_btn from "../../../components/btn/checkbox_btn";
import CengJiuZhiGongSi from "../../gcs/public/cengJiuZhiGongSi";
import FenYe from "../../../components/page/FenYe";
import FenGeFu from "../../open/FenGeFu";
import _ from "lodash"
export default {
  name: 'geRenFuWuShangInfo',
  components: {FenGeFu, FenYe, CengJiuZhiGongSi, Checkbox_btn},
}
</script>
<script setup>
import {ref, onMounted} from 'vue'
import http from "../../../api/http";
import XEUtils from "xe-utils";
import publicApi from "../../../api/dataList/publicApi";
import {useRouter} from 'vue-router'

const router = useRouter()
const jiuZHi = ref(null);
let form = ref({})
let ji_neng_list = ref([])

onMounted(() => {
  getData()
})
publicApi.getShangChangJiNeng().then(da => {
  ji_neng_list.value = da
})

function getData() {
  http.get('/gzhphp/user/getGeRenGongChengShi').then(da => {
    if (da.code === 1) {
      if (XEUtils.isObject(da.data)) {
        _.set(da,'data.shan_chang_name',_.split(_.get(da,'data.shan_chang_name'),',').join('、'))
        form.value = da.data
        if (!XEUtils.isArray(form.value.json_gcs_gzjl) || form.value.json_gcs_gzjl.length === 0) {
          jiuZHi.value.add();
        }
      }
    }
  })
}

function onSubmit() {
  http.post('/gzhphp/user/editGeRenGongChengShi', form.value).then(da => {
    if (da.code === 1) {
      router.push({name: 'EditCompanyInfo'})
    }
  })
}
</script>

<style scoped>
.jin-li {
  margin-bottom: 5px;
  width: 100%;
}
</style>
