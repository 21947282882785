<template>
  <van-form @submit="onSubmit">
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            v-model="form.gong_si.g_name"
            label="服务商名称"
        />
        <van-field
            readonly
            v-model="form.gong_si.g_abbreviation"
            label="服务商简称"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15"
    >
      <van-cell-group inset>
        <van-field
            readonly
            v-model="form.fws.fu_wu_type"
            label="服务类型"
        />
        <van-field
            readonly
            v-model="form.fws.fu_wu_di_qu"
            label="服务地区"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            v-model="form.gong_si.php_address"
            label="公司地址"
        />
        <van-field
            readonly
            v-model="form.gong_si.g_address"
            label="详细地址"
        />
        <van-field
            readonly
            v-model="form.gong_si.bank_card_number"
            label="银行账号"
        />
        <van-field
            readonly
            v-model="form.gong_si.opening_bank"
            label="开户行"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            label="营业执照"
        >
          <template #input>
            <add-img disabled v-model:form="form.gong_si.yin_ye_zhi_zhao"/>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            v-model="form.name"
            label="资质图片"
        >
          <template #input>
            <add-img disabled v-model:form="form.fws.db_zi_zhi"/>
          </template>
        </van-field>

      </van-cell-group>
    </div>
    <div class="form-btn">
      <van-button round block :to="{name:'UserCompanyInfoEdit'}" native-type="submit">
        编辑
      </van-button>
    </div>
  </van-form>
  <gong-si-cao-zuo v-if="isFzr"/>
</template>

<script>
import AddImg from "../../../components/file/AddImg";
import http from "../../../api/http";
import XEUtils from "xe-utils";
import GongSiCaoZuo from "./gongSiCaoZuo";
//预览服务商信息
export default {
  name: 'ServiceProviderInfo',
  components: {GongSiCaoZuo, AddImg},
  mixins: [],
  data() {
    return {
      form: {
        fws: {},
        gong_si: {}
      }
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      http.get('/gzhphp/user/getFwsInfo').then(da => {
        if (da.code === 1) {
          if (XEUtils.isObject(da.data.fws)) {

            da.data.fws.db_zi_zhi = XEUtils.map(da.data.fws.db_zi_zhi, n => {
              return {
                name: n.img_name,
                url: n.img_src
              }
            })

            this.form.fws = da.data.fws
          }
          if (XEUtils.isObject(da.data.gong_si)) {
            da.data.gong_si.php_address = da.data.gong_si?.php_address?.name?.join('/')

            da.data.gong_si.yin_ye_zhi_zhao = [
              {
                name: da.data.gong_si?.yin_ye_zhi_zhao?.gl_name,
                url: da.data.gong_si?.yin_ye_zhi_zhao?.image_url,
              }
            ]
            da.data.gong_si.yin_ye_zhi_zhao = da.data.gong_si.yin_ye_zhi_zhao.filter(n => n.name)

            this.form.gong_si = da.data.gong_si
          }
        }
      })
    },
    onSubmit() {

    }
  },
  watch: {},
  computed: {}
}
</script>
<script setup>
import {userBasis} from "@/views/user/public/Basis";

let {isFzr} = userBasis()
</script>
<style scoped>

</style>
