<template>
  <van-form @submit="onSubmit">
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            required
            v-model="form.gong_si.g_name"
            label="服务商名称"
        />
        <van-field
            v-model="form.gong_si.g_abbreviation"
            label="服务商简称"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15"
    >
      <van-cell-group inset>
        <fu-wu-shang-type required v-model:name="form.fws.fu_wu_type" title="服务类型"/>
        <Address title="服务地区" required v-model:name="form.fws.fu_wu_di_qu" data-type="fu_wu_di_qu"/>

      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <Address title="公司地址" required v-model:id="form.gong_si.g_area_id"/>
        <van-field
            v-model="form.gong_si.g_address"
            required
            label="详细地址"
        />
        <van-field
            v-model="form.gong_si.bank_card_number"
            label="银行账号"
        />
        <van-field
            v-model="form.gong_si.opening_bank"
            label="开户行"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            required
            label="营业执照"
        >
          <template #input>
            <add-img length="1" v-model:form="form.gong_si.yin_ye_zhi_zhao"/>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            required
            v-model="form.name"
            label="资质图片"
        >
          <template #input>
            <add-xie-yi
                v-model:form="form.fws.db_zi_zhi"
            />
          </template>
        </van-field>

      </van-cell-group>
    </div>
    <div class="form-btn">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import AddImg from "../../../components/file/AddImg";
import http from "../../../api/http";
import XEUtils from "xe-utils";
import FuWuShangType from "../../../components/select/FuWuShangType";
import Address from "../../../components/select/Address";
import AddRuZhiXieYi from "../../../components/list/AddRuZhiXieYi";
import AddXieYi from "../../../components/list/AddXieYi";
import din_shi from "../../../api/din_shi";
//编辑服务商信息
export default {
  name: 'ServiceProviderInfoEdit',
  components: {AddXieYi, AddRuZhiXieYi, FuWuShangType, AddImg, Address},
  mixins: [],
  data() {
    return {
      form: {
        fws: {},
        gong_si: {}
      }
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      http.get('/gzhphp/user/getFwsInfo').then(da => {
        if (da.code === 1) {
          if (XEUtils.isObject(da.data.fws)) {

            da.data.fws.db_zi_zhi = XEUtils.map(da.data.fws.db_zi_zhi, n => {
              return {
                name: n.img_name,
                url: n.img_src,
                dengJi: n.deng_ji
              }
            })

            this.form.fws = da.data.fws
          }
          if (XEUtils.isObject(da.data.gong_si)) {
            da.data.gong_si.php_address = da.data.gong_si?.php_address?.name?.join('/')

            da.data.gong_si.yin_ye_zhi_zhao = [
              {
                name: da.data.gong_si?.yin_ye_zhi_zhao?.gl_name,
                url: da.data.gong_si?.yin_ye_zhi_zhao?.image_url,

              }
            ]
            da.data.gong_si.yin_ye_zhi_zhao = da.data.gong_si.yin_ye_zhi_zhao.filter(n => n.name)

            this.form.gong_si = da.data.gong_si
          }
        }
      })
    },
    onSubmit() {
      let data =  JSON.parse(JSON.stringify(this.form));
      data.fws.db_zi_zhi = XEUtils.map(data.fws.db_zi_zhi, n => {
        return {
          'deng_ji': n.dengJi,
          'img_name': n.name,
          'img_src': n.url
        }
      })
      data.gong_si.yin_ye_zhi_zhao = XEUtils.map(data.gong_si.yin_ye_zhi_zhao, n => {
        return {
          'image_url': n.url,
          'gl_name': n.name,
        }
      })

      http.post("/gzhphp/user/editFwsInfo",data).then(da => {
        if (da.code === 1) {
          din_shi.success(da.msg).then(da => {
            this.$router.push({name: 'EditCompanyInfo'})
          })
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>

</style>
