<template>
  <nav-header fixed :title="title"/>
  <!--  客户  -->
  <company-info-edit v-if="jiaoSe === '客户'"/>
  <!--  服务商  -->
  <service-ge-ren-info-edit v-if="jiaoSe ==='个人服务商'"/>
  <service-provider-info-edit v-if="jiaoSe === '企业服务商'"/>
</template>

<script>
import ServiceProviderInfo from "./public/ServiceProviderInfo";
import http from "../../api/http";
import CompanyInfo from "./public/CompanyInfo";
import ServiceProviderInfoEdit from "./public/ServiceProviderInfoEdit";
import CompanyInfoEdit from "./public/CompanyInfoEdit";
import ServiceGeRenInfoEdit from "./public/ServiceGeRenInfoEdit";
import NavHeader from "../../components/top/NavHeader";

export default {
  name: 'UserCompanyInfoEdit',
  components: {NavHeader, ServiceGeRenInfoEdit, CompanyInfoEdit, ServiceProviderInfoEdit},
}
</script>
<script setup>
import {computed} from 'vue'
import {userBasis} from "./public/Basis";
import $ from 'jquery'

let {jiaoSe} = userBasis()
let title = computed(() => {
  let val = '公司信息'
  if (jiaoSe.value !== '客户') {
    val = '服务商信息'
  }
  val = '编辑' + val
  $('title').html(val)
  return val
})
</script>

<style scoped>

</style>
