<template>
<!--案例
<FenYe
        url="/serviceprovider/index/gei_xiang_mu_jing_du"
        v-model:data="list"
        :where="where"
    />
-->
  <div>
    <van-pagination
        v-if="total"
        v-model="currentPage"
        :total-items="total"
        mode="simple"
        :items-per-page="pageSize"
        @change="getData"
    />
    <van-empty v-else/>
  </div>
</template>

<script>
import {Options, Vue} from 'vue-class-component';
import axios from "@/api/http.js"
import moment from "moment";
export default {
  name:'FenYe',
  props: {
    url:String,
    where:{
      type:Object,
      default(){
        return {}
      }
    },
    //配置每页数量
    pageSize:{
      type:Number,
      default() {
        return 20
      }
    }

  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      urlWhere:{},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.urlWhere = this.where
      this.urlWhere.pageSize = this.pageSize
      this.urlWhere.page = this.currentPage
      axios.get(this.url,{
        params:this.urlWhere
      }).then(da=>{
        if(da.code !=1){
          return
        }
        this.total = da.data.total
        this.$emit("update:data",da.data.data)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
